<template>
  <div class="h-screen w-full bg-white">
    <auth-logo-banner/>

    <div class="vx-row no-gutter w-full items-center justify-center pb-base bg-white">
      <div class="vx-col w-full sm:w-6/12 md:w-5/12 lg:w-4/12 xl:w-3/12">
        <vx-card no-shadow card-border>
          <div slot="no-body">
            <div class="vx-row no-gutter justify-center items-center p-8">
              <div class="vx-col w-full">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">{{ $t('$Auth.InviteSignUpTitle') }}</h4>
                  <p class="text-justify">{{ $t('$Auth.InviteSignUpMsg') }}</p>
                </div>

                <form @submit.prevent>
                  <vs-input
                    v-model="model.name"
                    class="w-full required"
                    :label="$t('$General.Name')"
                    :name="$t('$General.Name')"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    v-validate="'required|alpha_spaces|min:2|max:35'"
                    data-vv-validate-on="input"
                    :danger="errors.has($tc('$General.Name'))"
                    :danger-text="errors.first($tc('$General.Name'))"
                    val-icon-danger="clear"/>

                  <vs-input
                    v-model="model.password"
                    type="password"
                    class="w-full required mt-8"
                    :name="$t('$General.Password')"
                    :label="$t('$General.Password')"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    autocomplete="new-password"
                    v-validate="'required|min:6|max:30'"
                    data-vv-validate-on="input"
                    :danger="errors.has($tc('$General.Password'))"
                    :danger-text="errors.first($tc('$General.Password'))"
                    val-icon-danger="clear"/>

                  <div class=" mt-8">
                    <vs-button
                      class="w-full"
                      @click.prevent="signUp()">
                      {{ $t('$Auth.SignUp') }}
                    </vs-button>

                    <vs-button
                      type="border"
                      to="/login"
                      class="w-full mt-3">
                      {{ $t('$Auth.BackToLogin') }}
                    </vs-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AuthLogoBanner from '@/views/pages/auth/components/AuthLogoBanner.vue';

/**
 * Page to complete sign up by invitation
 *
 * @module views/pages/auth/TheInviteRegister
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} model - The user info to save
 * @vue-data {string} securityToken - security token to validate request
 * @vue-data {string} email - email to validate request
 * @vue-event {void} signUp - make register action and notify the user
 */
export default {
  name: 'TheInviteRegister',
  components: {
    AuthLogoBanner,
  },
  data() {
    return {
      model: {
        name: '',
        password: '',
      },
      securityToken: this.$route.params.securityToken,
      email: this.$route.params.email,
    };
  },
  methods: {
    ...mapActions({
      invitedSignUp: 'auth/invitedSignUp',
    }),
    async signUp() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid || !this.securityToken || !this.email) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      this.$vs.loading({ type: 'radius' });
      await this.invitedSignUp({
        securityToken: this.securityToken,
        email: this.email,
        user: this.model,
      });
      this.$vs.loading.close();

      this.$showSuccessActionNotification({
        title: this.$t('$Auth.SignUpAttempt'),
        text: this.$t('$Auth.SuccessfullySignUpMsg'),
      });

      await this.$router.push({ name: 'home' });
    },
  },
};
</script>
