<template>
  <img
    v-if="full"
    src="@/assets/img/grapesend/logo/imagotipo-uc.svg" alt="grapesend"
    :style="{width, height}">
  <img
    v-else
    src="@/assets/img/grapesend/logo/isotipo.svg" alt="grapesend"
    :style="{width, height}">
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: String,
      required: false,
      default: 'auto',
    },
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
  },
};
</script>
